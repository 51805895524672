<template>
  <div>
    <div class='cepContact A4Container flexCloumnCenter pageBreak_no'>
      <div class='flexCenter fontFamily_HWZS' style='height: 50px;'>实习报告</div>
      <div class='form'>
        <el-row class=''>
          <el-col :span='4' class='flexCenter cell cell46' style='padding: 0 10px; border-left: 0;'>实习科室</el-col>
          <el-col :span='8' class='flexCenter cell cell46 overMoreLine'
            style='white-space: normal;word-break: break-all;padding: 0 5px;box-sizing: border-box;'>
            {{ dataInfo.dept }}
          </el-col>
          <el-col :span='4' class='flexCenter cell cell46' style='padding: 0 10px;'>科室名称</el-col>
          <el-col :span='8' class='flexCenter cell cell46 overMoreLine'
            style='white-space: normal;word-break: break-all;padding: 0 5px;box-sizing: border-box;'>
            {{ dataInfo.deptName }}
          </el-col>
        </el-row>

        <el-row class='flexStart' style='display: flex;'>
          <el-col :span='4' class='flexCenter cell cell46' style=' border-left: 0;'>实习时间</el-col>
          <el-col :span='20' class='flexStart cell cell46'
            style='white-space: normal;word-break: break-all;padding: 0 10px;box-sizing: border-box;'>
            {{ dataInfo.weekdate }}
          </el-col>
        </el-row>


        <el-row class='' style='width: 100%;height: calc( 100% - 92px );'>
          <el-row class='flexStretch' style='height: 100%;'>
            <el-col :span='2' class='cellMore flexCenter' style='border-left: 0;'>实<br />习<br />内<br />容</el-col>
            <el-col :span='22' class='flexCloumn' style='height: 100%;'>
              <!--              一、-->
              <div style='width: 100%;'>
                <el-row class='flexStart cell FS18 bold' style='padding: 0 3px;'>一、接触或应用到那些专科理论知识</el-row>
                <el-row class='cellMore' style='width: 100%;height: 116px;'>
                  <div class='content'
                    style='width:100%;height: 100%;overflow: hidden;box-sizing: border-box;position: relative;'>
                    <div :id="'divCntnrA_' + idx" style='width:100%;height: 100%;overflow: hidden;'>
                      <div :id="'resizeDivA_' + idx" style='width: 100%;word-break: break-all'>
                        {{ dataInfo.knowledge || '-' }}
                      </div>
                    </div>
                  </div>
                </el-row>
              </div>
              <!--              二、-->
              <div class='' style='width: 100%;'>
                <el-row class='flexStart cell FS18 bold' style='padding: 0 3px;'>二、护理操作</el-row>
                <el-row class="">
                  <el-col :span="12" class="flexCenter cell cell46"
                    style=''>操&nbsp;&nbsp;作&nbsp;&nbsp;项&nbsp;&nbsp;目</el-col>
                  <el-col :span="2" class="flexCenter cell cell46">次数</el-col>
                  <el-col :span="10" class="flexCloumn">
                    <el-row class='cell flexCenter' style='height: 23px;min-height: unset;'>自我评价</el-row>
                    <el-row class='' style='height: 23px;min-height: unset;'>
                      <el-col :span="8" class='cell flexCenter' style='height: 23px;min-height: unset;'>熟悉</el-col>
                      <el-col :span="8" class='cell flexCenter' style='height: 23px;min-height: unset;'>掌握</el-col>
                      <el-col :span="8" class='cell flexCenter' style='height: 23px;min-height: unset;'>熟练掌握</el-col>
                    </el-row>
                  </el-col>
                </el-row>
                <el-row class='flexStretch' v-for='(item, index) in dataInfo.project'>
                  <el-col :span='12'>
                    <div style='width: 100%;'>
                      <el-row class='cell flexCenter' style=''>{{ item.name }}</el-row>
                    </div>
                  </el-col>

                  <el-col :span='2'>
                    <div style='width: 100%;'>
                      <el-row class='cell flexCenter'>{{ item.num }}</el-row>
                    </div>
                  </el-col>
                  <el-col :span='10' class='flexCloumn'>
                    <el-row>
                      <el-col :span='8' class='flexCenter cell'>{{ item.radio == 1 ? '✓' : '' }}</el-col>
                      <el-col :span='8' class='flexCenter cell'>{{ item.radio == 2 ? '✓' : '' }}</el-col>
                      <el-col :span='8' class='flexCenter cell'>{{ item.radio == 3 ? '✓' : '' }}</el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </div>
              <!--  三、-->
              <div class='' style='width: 100%;'>
                <el-row class='flexStart cell FS18 bold' style='padding: 0 3px;'>三、专科健康教育（沟通次数与内容）</el-row>
                <el-row class="">
                  <el-col :span="4" class="flexCenter cell" style=''>时间</el-col>
                  <el-col :span="4" class="flexCenter cell">病种</el-col>
                  <el-col :span="12" class="flexCenter cell">主要内容</el-col>
                  <el-col :span="4" class="flexCenter cell">效果评价</el-col>
                </el-row>
                <el-row class='flexStretch' v-for='(item, index) in dataInfo.chart'>
                  <el-col :span="4" class="flexCenter cell" style=''>{{ item.time != '请选择' ? item.time : '' }}</el-col>
                  <el-col :span="4" class="flexCenter cell"></el-col>
                  <el-col :span="12" class="flexCenter cell">{{ item.content }}</el-col>
                  <el-col :span="4" class="flexCenter cell">{{ item.comment }}</el-col>
                </el-row>
              </div>
              <!--              四、-->
              <div style='width: 100%;height: calc( 100% - 555px );'>
                <el-row class='flexStart cell FS18 bold' style='padding: 0 3px;'>四、病区讲座（时间、内容及感想）</el-row>
                <el-row class='cellMore' style='width: 100%;height: calc( 100% - 30px );'>
                  <div class='content'
                    style='width:100%;height: 100%;overflow: hidden;box-sizing: border-box;position: relative;'>
                    <div :id="'divCntnrB_' + idx" style='width:100%;height: 100%;overflow: hidden;'>
                      <div :id="'resizeDivB_' + idx" style='width: 100%;word-break: break-all'>
                        {{ dataInfo.lecture || '-' }}
                      </div>
                    </div>
                  </div>
                </el-row>
              </div>
            </el-col>

          </el-row>
        </el-row>
      </div>

    </div>
  </div>
</template>

<script>

export default {
  name: 'Report1',
  data() {
    return {
      dataover: false,
      userid: '',
      info: {
        ksInfo: {}, evaluation: {}, identification: {},
      },
      list: [
        { name: '热爱专业虚心好学', value: 10 }, { name: '顾全大局团结协作', value: 10 },
        { name: '尊重病人一视同仁', value: 10 }, { name: '遵守纪律坚守岗位', value: 10 },
        { name: '工作负责服务周到', value: 10 }, { name: '诚实严谨保守医秘', value: 10 },
        { name: '语言文明礼貌待人', value: 10 }, { name: '着装规范仪表端庄', value: 10 },
        { name: '尊敬老师服从安排', value: 10 }, { name: '廉洁奉公不谋私利', value: 10 }
      ]
    }
  },
  props: {
    idx: {
      type: Number,
      default: 1
    },
    dataInfo: {
      type: Object,
      default: {}
    },
  },
  mounted() {
    console.log(154,this.dataInfo)
    this.init()
  },
  methods: {
    init() {
      let _this = this

      setTimeout(function () {

        _this.$nextTick(() => {
          _this.resizeA()
        })
      }, 600)

    },
    stripscript(s) {
      var pattern = new RegExp('\n')
      var rs = ''
      for (var i = 0; i < s.length; i++) {
        rs = rs + s.substr(i, 1).replace(pattern, '')
      }
      return rs
    },
    resizeA() {
      let divHeight = document.getElementById(`divCntnrA_${this.idx}`).offsetHeight
      let textHeight = document.getElementById(`resizeDivA_${this.idx}`).offsetHeight
      // console.log(' divHeight, textHeight', divHeight, textHeight)
      let i = 1
      while (textHeight > divHeight && i < 5) {
        document.getElementById(`resizeDivA_${this.idx}`).style.fontSize = `${14 - i}px`
        textHeight = document.getElementById(`resizeDivA_${this.idx}`).offsetHeight
        i++
      }
    },
    resizeB() {
      let divHeight = document.getElementById(`divCntnrB_${this.idx}`).offsetHeight
      let textHeight = document.getElementById(`resizeDivB_${this.idx}`).offsetHeight
      // console.log('b', this.idx, divHeight, textHeight)
      let i = 1
      while ((textHeight > divHeight) && i < 5) {
        document.getElementById(`resizeDivB_${this.idx}`).style.fontSize = `${14 - i}px`
        textHeight = document.getElementById(`resizeDivB_${this.idx}`).offsetHeight
        i++
      }
    }
  }
}
</script>


<style lang="less" type="text/less">
.cepContact .el-col {
  display: flex;
  padding: 0;
  margin: 0;
}

.cepContact .el-row {
  width: 100%;
  padding: 0;
  margin: 0;
}
</style>
<style scoped="scoped" type="text/less" lang="less">
@font-face {
  font-family: "华文中宋";
  src: url("/public/font/hwzs.ttf");
}

.fontFamily_HWZS {
  font-family: '华文中宋';
}

.flexStretch {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
}

.cepContact {
  font-size: 20px;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 25px 0;

  .title {
    font-size: 20px;
    line-height: 50px;
  }

  .ydyf {
    .nth:nth-of-type(2n+1) {
      .bl0 {
        border-left: 0;
      }
    }
  }

  .title {
    font-size: 20px;
    line-height: 50px;
  }

  .form {
    width: calc(100% - 50px);
    height: calc(100% - 50px);
    border: 1px solid #000;
    border-top: 0;

    div {
      font-size: 14px;
      color: #323232;
      box-sizing: border-box;
    }

    .content {
      width: 100%;
      height: 100%;
      padding: 10px;
      white-space: break-spaces;
      word-break: break-all;
      overflow: hidden;
    }

    .el-row {
      //border-top: 1px solid #000;
      //border-bottom: 1px solid #000;
    }
  }

  .col-row {
    width: 100%;
    margin: 0;
    padding: 0;
  }
}


.cepContact {

  .table {
    width: 100%;
  }

  .borderR {
    border-right: 1px solid #333;
  }

  .borderB {
    border-bottom: 1px solid #333;
  }

  .FS18 {
    font-size: 18px;
  }

  .bold {
    font-weight: 700;
    font-family: Avenir, Helvetica, Arial, sans-serif;
  }

  .cell {
    border-left: 1px solid #333;
    border-top: 1px solid #333;

    min-height: 30px;
    //line-height: 30px;
    //text-align: center;
    white-space: nowrap;
    font-size: 14px;
    font-family: '华文中宋';

    word-break: break-all;
    white-space: normal;
  }

  .cell46 {
    min-height: 46px;
    line-height: 46px;
    font-size: 14px;
    font-family: '华文中宋';
  }

  .cell50 {
    min-height: 50px;
    line-height: 50px;
    font-size: 14px;
    font-family: '华文中宋';

  }

  .cellMore {
    border-left: 1px solid #333;
    border-top: 1px solid #333;
    min-height: 50px;
    text-align: left;
    font-size: 14px;
    font-family: '华文中宋';

  }
}

.uline {
  text-decoration: underline;
}
</style>

